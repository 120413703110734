<template>
  <div>
    <header class="bg-white shadow border-t-4 border-indigo-600">
      <div class="container mx-auto px-6 py-4">
        <div class="flex items-center justify-between">
          <div>
            <span class="mx-3 font-medium text-sm md:text-base"
              >Shehryar Khan</span
            >
          </div>
        </div>
      </div>
    </header>

    <section class="bg-white mt-20">
      <div class="max-w-2xl px-6 text-center mx-auto">
        <h2 class="text-3xl font-semibold text-gray-800">
          Hi,
          <span class="bg-indigo-600 text-white rounded px-1"
            >I’m Shehryar.</span
          >
        </h2>
        <p class="text-gray-600 mt-4">
          Co-Founder & CTO @
          <a
            href="https://www.marketpryce.com
            "
            >Marketpryce
          </a>
        </p>
        <section
          class="
            hero
            container
            max-w-screen-lg
            mx-auto
            pb-10
            flex
            justify-center
          "
        >
          <!-- <v-img
            lazy-src="https://picsum.photos/id/11/10/6"
            max-height="250"
            max-width="250"
            src="../assets/shehryar.png"
          ></v-img> -->
        </section>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.instagram {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
</style>