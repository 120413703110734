<template>
  <v-app>
    <HelloWorld />
    <Footer />
  </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld";
import Footer from "./components/Footer";

export default {
  name: "App",

  components: {
    HelloWorld,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>
