<template>
  <v-footer absolute :padless="padless">
    <v-card flat  width="100%" class="blue s text-center">
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          :href="icon.link"
          class="mx-4"
          icon
        >
          <v-icon size="24px">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    icons: [
      { icon: "mdi-facebook", link: "https://www.facebook.com/shrrkhan2" },
      { icon: "mdi-twitter", link: "https://twitter.com/shrrkhan" },
      { icon: "mdi-linkedin", link: "https://www.linkedin.com/in/shrrkhan/" },
      { icon: "mdi-instagram", link: "https://instagram.com/shrrkhan" },
      { icon: "mdi-instagram", link: "https://instagram.com/over.written" },
    ],
    items: ["default", "absolute", "fixed"],
    padless: false,
    variant: "default",
  }),
  computed: {
    localAttrs() {
      const attrs = {};

      if (this.variant === "default") {
        attrs.absolute = false;
        attrs.fixed = false;
      } else {
        attrs[this.variant] = true;
      }
      return attrs;
    },
  },
};
</script>
